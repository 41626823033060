<template>
  <div class="collection-sec1">
    <div class="prolistbox-sec1">
        <div class="weeb_load basefont40"  v-if="error==0">加载中....</div>
        
        <template v-if="error==1">
          <div class="f_tit basefont30" >我的收藏</div>
          <div class="cont">
              <div class="item" v-for="(item,index) in prolist" :key="index">
                <div class="img">
                  <img :src="item.spuDefaultImg" :alt="item.spuName">
                </div>
                <div class="font">
                  <div class="tit">
                    {{item.spuName}}
                  </div>
                  <div class="text" v-html="item.spuDesc">
                  </div>
                  <div class="jiage">
                    <span class="name">¥ {{item.spuPrice}}</span>
                    <div class="label">
                      <span v-for="(it1,in1) in item.label" :key="in1">街头</span>
                    </div>
                  </div>
                </div>
                <div class="f_jut">
                  <div class="f_icon">
                    <!-- <a href="javascript:;" @click="shopgouwu($event,item,index)" class="li">
                      <img src="../../assets/images/gouwucheioncimg1.png" alt="">
                    </a> -->
                    <a href="javascript:;" :class="{li:true,shou:true,on:item.col}" @click="collect(index,item.spuId)">
                      <img src="../../assets/images/wushouchagn1.png" alt="">
                      <img src="../../assets/images/wushouchagn1-1.png" alt="">
                    </a>
                    <router-link :to="{name: 'Productlistinfo',params:{id:item.spuId,pan: 1}}"  class="li"><img src="../../assets/images/fgouwuimgf1.png" alt=""></router-link>
                  </div>
                  <!-- <div class="fz_but" @click="proaltShow()">
                    <div class="but">
                      去APP购买
                    </div>
                  </div> -->
                </div>
              </div>
          </div>
          <el-pagination
        hide-on-single-page
        layout="prev, pager, next"
        :page-size= limit
        :current-page = page 
        @current-change="curpage"
        :total= total
        class="basepage" style="margin-top: 46px">
        </el-pagination>
        </template>
        <div class="weeb_load basefont40"  v-if="error==3">您还没有收藏</div>
       
    </div>
  
  </div>
</template>

<script>
export default {
  data(){
    return {
      proin: false,
      xie:'',
      // prolist:[
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      //   {
      //     id: 1,
      //     url: require('../../assets/images/shoucangimgfw1.jpg'),
      //     title: "【 BASE KIT 】 PRO DAMPER UCF1",
      //     text:'<p>尺寸9(寸)：9.5J×21    J值／宽度：9.5J×21</p><p>孔距BP(mm)&孔数：9.5J×21    颜色：黑色</p>',
      //     jiage:"1800",
      //     label:["街头"],
      //     col: true
      //   },
      // ],
      prolist:null,
      page: 1, //页数
      limit: 8, //条数
      total: 0,
      error: 0,  //错误状态
    }
  },
  created () {
     this.xie=this.$route.params.title;
  },
  mounted:function(){
    // this.$axios.post('/api2/portal/auth/product/spuInfo/collectSpu').then(res=>{
    //   console.log(res);
    // }).catch(error=>{
    //   console.log(error);
    // });
    this.getProductList();
  },
  methods: {
    proaltShow(){
      $(".proinfoAlt").fadeIn();
    },
    proaltHide(){
      this.proin = false;
    },
    collect:function(index,id){
        // this.error=0;
        this.$axios.post("/api2/portal/auth/product/spuInfo/collectSpu",{
          spuId: id,
          type: 0
        }).then(res => {
          if(res.code=="000000"){
            this.prolist[index].col = !this.prolist[index].col;
            this.prolist.splice(index,1);
            this.$message({
                message: '取消收藏!',
                type: 'success',
            });
            if(this.page>1 && this.prolist.length==0){
              this.error=0;
              this.page=1;
              this.$nextTick();
            }
            this.getProductList();
          }
        }).catch(error => {
          console.log(error);
        });
      
    },
    shopgouwu:function(){ //添加购物车
      this.$message('添加成功');
    },
    getProductList(){
      // console.log(this.$store.state.loginStore.login.token);
      this.$axios.post("/api2/portal/auth/user/collectSpu",{
        current: this.page,
        size: this.limit,
      }).then(res => {
        if(res.data.data.length!=0){
          this.prolist = res.data.data;
          this.total = res.data.total;
          this.prolist.forEach(v => {
            v.col=true;
          });
          if(Math.ceil(this.total/this.limit)<2){
            this.pageTogle=false
          }else{
            this.pageTogle=true
          }
          this.error=1;
        }else{
          this.pageTogle=false
          // this.$message({
          //       message: '没有产品',
          //       type: 'error',
          //   });
            this.error=3;
        }
        
      }).catch(error => {
        console.log(error);
      });
    },
    curpage(val){
          this.page=val;
          this.$nextTick( () => {
              this.getProductList();
          });
    },
  }
}
</script>

<style scoped>
.fade-enter-active,.fade-leave-active{
  transition: all 0.6s linear;
}
.fade-enter,.fade-leave-to{
  opacity: 0;
}
</style>